import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './pages/auth/auth.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PostsComponent } from './pages/posts/posts.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ThoughtsComponent } from './pages/thoughts/thoughts.component';
import { UrlGuard } from './services/guard/url.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/post/987',
    pathMatch: 'full',
  },
  {
    path: 'post/:id',
    component: PostsComponent,
    canActivate: [UrlGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [UrlGuard],
  },
  {
    path: 'thought/:id',
    canActivate: [UrlGuard],
    component: ThoughtsComponent,
  },
  {
    path: 'login',
    canActivate: [UrlGuard],
    component: AuthComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
