import { Component } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';

import {
  ToolbarService,
  LinkService,
  ImageService,
  HtmlEditorService,
} from '@syncfusion/ej2-angular-richtexteditor';
import { StateService } from './services/state/state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService],
})
export class AppComponent {
  isAuth: boolean = false;
  constructor(private _state: StateService) {
    this._state.isAuthenticated.subscribe((res) => {
      this.isAuth = res;
    });
  }
  onResizeEnd(event: ResizeEvent): void {
    console.log('Element was resized', event);
  }
}
