<div class="auth-container">
  <div class="ac-mid">
    <!-- <p>You know what you need to do.</p> -->
    <img src="../../../assets/cmass.png" alt="" srcset="" />
    <h3>CMASS</h3>
    <input
      type="password"
      name=""
      (keyup.enter)="login(password.value)"
      #password
      id="password"
    />
  </div>
</div>
