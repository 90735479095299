import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from '../../services/state/state.service';
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
  constructor(private _state: StateService, private sanitizer: DomSanitizer) {}
  htmlBody;
  ngOnInit(): void {}

  ngAfterViewInit() {
    let that = this;
    that._state.previewHTMLBody.subscribe((res) => {
      that.htmlBody = this.sanitizer.bypassSecurityTrustHtml(res);
    });
  }
}
