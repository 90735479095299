<div class="page-not-found-container">
  <div class="page-not-found-mid-container">
    <div class="text-box">
      <h1>404</h1>
      <p>Content not found.🥸</p>
    </div>

    <img src="../../../assets/fullTrans.gif" alt="" srcset="" />
  </div>
</div>
