import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { NavComponent } from './components/nav/nav.component';
import { PreviewComponent } from './components/preview/preview.component';
import { ResizableModule } from 'angular-resizable-element';
import { PostsComponent } from './pages/posts/posts.component';
import { ThoughtsComponent } from './pages/thoughts/thoughts.component';
import { AuthComponent } from './pages/auth/auth.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SettingsComponent } from './pages/settings/settings.component';

@NgModule({
  declarations: [
    AppComponent,
    TextEditorComponent,
    NavComponent,
    PreviewComponent,
    PostsComponent,
    ThoughtsComponent,
    AuthComponent,
    NotFoundComponent,
    SettingsComponent,
  ],
  imports: [
    ResizableModule,
    BrowserModule,
    AppRoutingModule,
    RichTextEditorModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
