import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  constructor(private _router: Router) {}
  navList = {
    BLOGS: [
      {
        title: 'Introducing the pixel me.',
        _id: '21313',
      },
      {
        title:
          'How to host Angular Application on Azure Cloud Platform & Basics of Azure CP.',
        _id: '123131',
      },
      {
        title: 'Live To Be An Entrepreneur | The mindset',
        _id: '12313',
      },
      {
        title: 'Brief Summary on A New Approach for Software Risk Estimation',
        _id: '1241',
      },
      {
        title: 'Summary | Above the Clouds: A View of Cloud Computing',
        _id: '4124',
      },
    ],
    THOUGHTS: [
      {
        title: 'Mourning the death of a stranger.',
        _id: '2141',
      },
      {
        title: 'Opening the door for you.',
        _id: '41243',
      },
      {
        title: 'Mourning the death of a stranger.',
        _id: '2141',
      },
      {
        title: 'Opening the door for you.',
        _id: '41243',
      },
      {
        title: 'Mourning the death of a stranger.',
        _id: '2141',
      },
      {
        title: 'Opening the door for you.',
        _id: '41243',
      },
      {
        title: 'Mourning the death of a stranger.',
        _id: '2141',
      },
      {
        title: 'Opening the door for you.',
        _id: '41243',
      },
      {
        title: 'Mourning the death of a stranger.',
        _id: '2141',
      },
      {
        title: 'Opening the door for you.',
        _id: '41243',
      },
      {
        title: 'Mourning the death of a stranger.',
        _id: '2141',
      },
      {
        title: 'Opening the door for you.',
        _id: '41243',
      },
      {
        title: 'Mourning the death of a stranger.',
        _id: '2141',
      },
      {
        title: 'Opening the door for you.',
        _id: '41243',
      },
      {
        title: 'Mourning the death of a stranger.',
        _id: '2141',
      },
      {
        title: 'Opening the door for you.',
        _id: '41243',
      },
    ],
  };
  ngOnInit(): void {}
  toggleNavAc(tar) {
    console.log(tar);
    tar.classList.toggle('show');
  }
  newPost() {
    this.navList.BLOGS.push({
      title: 'New post',
      _id: '1323e4',
    });
  }

  toSetting() {
    this._router.navigate(['/settings']);
  }
}
