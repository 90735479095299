import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import sha256 from 'crypto-js/sha256';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';
@Injectable({
  providedIn: 'root',
})
export class StateService {
  password = 'hehahaha';

  private isAuthenticatedSource = new BehaviorSubject<boolean>(false);
  private previewHTMLBodySource = new BehaviorSubject<string>('');

  previewHTMLBody = this.previewHTMLBodySource.asObservable();

  isAuthenticated = this.isAuthenticatedSource.asObservable();

  constructor() {
    this._CheckAuth();
  }

  private async _CheckAuth() {
    const session = localStorage.getItem('password');
    if (session) {
      console.log(session);
      this.isAuthenticatedSource.next(true);
    } else {
      console.log('No SESSIOn:', session);
      this.isAuthenticatedSource.next(false);
    }
  }

  setAuthentication(bool) {
    this.isAuthenticatedSource.next(bool);
  }

  setLSPassword(password) {
    const { value, key, path, privateKey } = {
      value: password,
      key: 'myPassWord',
      path: '/login',
      privateKey: 'prelisa',
    };
    const hashDigest_VALUE = sha256(value);
    const hmacDigest_VALUE = Base64.stringify(
      hmacSHA512(path + hashDigest_VALUE, privateKey)
    );

    console.log(hmacDigest_VALUE);
    localStorage.removeItem(this.getKeyHash());
    localStorage.setItem(this.getKeyHash(), hmacDigest_VALUE);
  }

  getKeyHash() {
    const { key, path, privateKey } = {
      key: 'myPassWord',
      path: '/login',
      privateKey: 'prelisa',
    };
    const hashDigest_KEY = sha256(key);
    const hmacDigest_KEY = Base64.stringify(
      hmacSHA512(path + hashDigest_KEY, privateKey)
    );

    return hmacDigest_KEY;
  }

  getHash(val) {
    const { key, path, privateKey } = {
      key: val,
      path: '/login',
      privateKey: 'prelisa',
    };
    const hashDigest_KEY = sha256(key);
    const hmacDigest_KEY = Base64.stringify(
      hmacSHA512(path + hashDigest_KEY, privateKey)
    );

    return hmacDigest_KEY;
  }

  setHTMLPreviewBody(body) {
    this.previewHTMLBodySource.next(body);
  }
}
