<div class="posts-container">
  <div class="editor-container">
    <div class="post-details">
      <div class="details">
        <div>
          <input
            type="text"
            value="Introducing the pixel me."
            placeholder="Title"
            id="title"
          />
        </div>
        <div>
          <input type="text" value="2020-11-01" placeholder="Date" id="date" />
        </div>
      </div>
      <div class="cta">
        <button>Post</button>
        <button>Update</button>
        <button class="delete">Delete</button>
      </div>
    </div>
    <app-text-editor></app-text-editor>
  </div>
  <div class="preview-container"><app-preview></app-preview></div>
</div>
