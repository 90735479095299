import { Component, OnInit, ViewChild } from '@angular/core';
import { enableRipple } from '@syncfusion/ej2-base';
import { StateService } from 'src/app/services/state/state.service';
import {
  ToolbarService,
  LinkService,
  ImageService,
  HtmlEditorService,
  QuickToolbarService,
} from '@syncfusion/ej2-angular-richtexteditor';

enableRipple(true);

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    QuickToolbarService,
  ],
})
export class TextEditorComponent implements OnInit {
  public tools: object = {
    items: [
      'Undo',
      'Redo',
      '|',
      'Bold',
      'Italic',
      'Underline',
      '|',

      'FontSize',
      'FontColor',

      '|',
      'SubScript',
      'SuperScript',

      '|',
      'Formats',
      'Alignments',
      '|',
      'UnorderedList',
      '|',
      'Indent',
      'Outdent',
      '|',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'SourceCode',
      '|',
      'FullScreen',
    ],
  };
  public inlineMode: object = { enable: true, onSelection: true };
  public toolbarSettings: Object = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'Formats',
      '-',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'CreateLink',
      '-',
      'Image',
      'SubScript',
      'SuperScript',
      'FullScreen',
      '-',
      'FontColor',
      'ClearFormat',
      'SourceCode',
    ],
  };
  public format: Object = {
    width: 'auto',
  };
  public fontFamily: Object = {
    width: 'auto',
  };
  @ViewChild('valueTemplate') input;
  body = '';
  constructor(private _state: StateService) {}

  ngAfterViewInit() {
    let that = this;
    setInterval(function (e) {
      that._state.setHTMLPreviewBody(that.checkState());
    }, 1000);
    // this._state.previewHTMLBody.subscribe(
    //   (message) => {
    //     console.log(message != that.body);
    //     if (message != that.body) {
    //       that._state.setHTMLPreviewBody(that.body);
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );

    // let rteValue: string = this.rteObj.value;
  }

  ngOnInit(): void {}
  checkState() {
    return this.body;
  }
}
