import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class UrlGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    console.log(state, next);
    const lsd = localStorage.getItem(this._state.getKeyHash());
    console.log(lsd);
    const authState = lsd == null ? false : true;
    if (
      state.url.includes('post') ||
      state.url.includes('thought') ||
      state.url.includes('settings')
    ) {
      if (authState) {
        if (lsd == this._state.getHash(this._state.password)) {
          this._state.setAuthentication(true);
          return true;
        } else {
          this._state.setAuthentication(false);
          localStorage.removeItem(this._state.getKeyHash());
          this._router.navigate(['/login']);
          return false;
        }
      } else {
        this._router.navigate(['/login']);
        return false;
      }
    } else {
      if (state.url.includes('login') && authState) {
        this._router.navigate(['/post/211']);
        this._state.setAuthentication(true);
        return false;
      } else {
        if (authState) {
          this._state.setAuthentication(true);
        } else {
          this._state.setAuthentication(false);
        }
        return true;
      }
    }
  }

  constructor(private _state: StateService, private _router: Router) {}
}
