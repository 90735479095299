<div class="settings-container">
  <div class="sc-mid">
    <div class="options">
      <div class="active">Preview Settings</div>
      <div>Endpoints</div>
    </div>
    <div class="option-contents">
      <div class="preview-container">
        <h4>HTML script of main body</h4>
        <div class="screens">
          <div class="screen-card selected">
            <div class="img-overflow-control">
              <img src="assets/icon1.png" alt="" srcset="" />
            </div>
            <div class="body">Post</div>
          </div>
          <div class="screen-card">
            <div class="img-overflow-control">
              <img src="assets/icon1.png" alt="" srcset="" />
            </div>
            <div class="body">Thoughts</div>
          </div>
        </div>
        <div class="preview-ind-settings">
          <h3>Post Preview Setting</h3>
          <h4>
            Preview page style stript. <span class="fileType">type: .scss</span>
          </h4>
          <textarea id="ind-css" cols="30" rows="10"></textarea>
          <h4>HTML Script <span class="fileType">type: .html</span></h4>
          <textarea id="ind-css" cols="30" rows="10"></textarea>
          <button>Save</button>
        </div>
      </div>
    </div>
    <button class="logout" (click)="logout()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        enable-background="new 0 0 24 24"
        height="16px"
        viewBox="0 0 24 24"
        width="16px"
      >
        <g><path d="M0,0h24v24H0V0z" fill="none" /></g>
        <g>
          <path
            d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z"
          />
        </g>
      </svg>
      Logout
    </button>
  </div>
</div>
