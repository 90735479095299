import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StateService } from '../../services/state/state.service';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor(private _router: Router, private _state: StateService) {}

  ngOnInit(): void {}
  login(val) {
    if (val == this._state.password) {
      document.getElementById('password').classList.remove('invalid');
      this._state.setLSPassword(val);
      this._router.navigate(['/post/27987']);
    } else {
      console.log(false);
      document.getElementById('password').classList.add('invalid');
    }
  }
}
